export default [
  // DASHBOARD
  {
    title: 'Dashboard',
    route: 'page1',
    tag: '2',
    icon: 'HomeIcon',
    role: [2],
    children: [
      {
        title: 'Analytics',
        route: 'analytic',
        icon: 'CircleIcon',
        role: [2],
      },
      {
        title: 'Map View',
        route: 'map-view',
        icon: 'CircleIcon',
        role: [2],
      },
    ],
  },
  {
    header: 'Component',
    role: [2],
  },
  {
    title: 'Media Gallery',
    route: 'media-gallery',
    icon: 'FolderIcon',
    role: [2],
  },
  {
    title: 'File Manager',
    route: 'file-view',
    icon: 'FolderIcon',
    role: [2],
  },
  
 

  
  {
    header: 'REPORT',
    role: [2],
  },
 
  {
    title: 'Manhour Report',
    route: 'manhour',
    icon: 'MailIcon',
    role: [2],
  },

  // {
  //   title: 'E-PDMS',
  //   route: 'e-pdms',
  //   icon: 'MailIcon',
  //   role: [2],
  // },
  {
    title: 'Incident Report',
    route: 'incident',
    icon: 'FileTextIcon',
    role: [2],
  },

  {
    header: 'EPDMS',
    role: [2],
  },
  {
    title: 'PEDMS',
    route: 'file-pedms',
    icon: 'InboxIcon',
    role: [2],
  },
  {
    title: 'Viewer',
    route: 'viewer',
    icon: 'MapIcon',
    role: [2],
  },
 
]
