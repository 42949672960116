  <!-- #Change 2
  user dropdown for user shortcut options
  -->
<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div
        class="d-sm-flex d-none user-nav"
      >
        <span
          class="user-name font-weight-bold  mb-0 text-dark"
        >
          {{ userData.username }}
        </span>
        <span
          class="user-status text-dark"
        >
          <!-- {{ userData.role[0].role_name }} -->
          Admin
        </span>
      </div>
      <b-avatar
        size="40"
        variant="info"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
          stroke="blue"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    > -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'edit-dashboard' }">
      <feather-icon
        size="16"
        icon="CalendarIcon"
        class="mr-50"
      />
      <span>Edit Dashboard</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>
<style>
</style>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
      userData: JSON.parse(localStorage.getItem('userData-Core')),
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('userData-Core')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
